import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
// import BackgroundImage from "gatsby-background-image"

import TariffGlobal from "../components/TariffGlobalCard"
import Reasons from "../components/reasons"
import OfferCarousel from "../components/OfferCarousel"
import LatestPosts from "../components/blog/LatestPosts"
import Layout from "../components/layout/basic"
import SEO from "../components/global/SEO"
import MapCover from "../components/mapCover"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import CardDeck from "react-bootstrap/CardDeck"

export const query = graphql`
  query($slug: String!) {
    city: contentfulCity(seo: { slug: { eq: $slug } }) {
      id
      cityName
      district {
        name
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      seo {
        slug
        title
        metaDescription
      }
      cityImage {
        fixed(width: 200) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
    }
    cottage: contentfulAsset(title: { eq: "cottage" }) {
      fluid(maxWidth: 1024) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    flat: contentfulAsset(title: { eq: "flat" }) {
      fluid(maxWidth: 1024) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    store: contentfulAsset(title: { eq: "store" }) {
      fluid(maxWidth: 1024) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    tvproviders: allContentfulTvProvider(
      filter: { node_locale: { eq: "uk" } }
    ) {
      nodes {
        id
        name
        seo {
          slug
        }
        logo {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
          fixed(width: 250, height: 150, resizingBehavior: PAD, quality: 85) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
    }
    offers: allContentfulPromoOffer(filter: { node_locale: { eq: "uk" } }) {
      nodes {
        id
        title
        slogan {
          childMarkdownRemark {
            html
          }
        }
        offerImage {
          fluid(maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`

const CityTemplate = ({
  data: { city, cottage, flat, store, tvproviders, offers },
}) => {
  // const backgroundFluidLinearGradient = [
  //   cottage.fluid,
  //   `radial-gradient(at 50% 100%, rgba(181, 45, 168, 0.75), rgb(15, 1, 94, 1))`,
  // ].reverse()

  return (
    <Layout>
      <SEO
        title={city.seo.title}
        description={city.seo.metaDescription}
        // image={`https:${city.cityImage.fixed.src}`}
        url={`/internet-${city.seo.slug}/`}
      />
      <div className="hero-background-light">
        <Container className="py-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Newtelecom</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {city.cityName}
              </li>
            </ol>
          </nav>
          <Row>
            <Col lg={8} md={6} sm={12}>
              <h1 className="gradient-text">{city.cityName}</h1>
              <div className="hero-slogan py-4">
                Швидкісний безлімітний Інтернет в будинок, квартиру або для
                бізнесу в селі {city.cityName}, {city.district.name} район
                Київської області.
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Img
                fixed={city.cityImage.fixed}
                alt={`${city.cityName} - герб`}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <section>
        <Container>
          <h2 className="my-5">Інтернет в селі {city.cityName}</h2>
          <Row>
            <TariffGlobal
              tariffGName="Інтернет в Будинок"
              tariffGPrice="200"
              tariffGSpeed="1000"
              tariffGImageFluid={cottage.fluid}
              tariffGURL="/internet-v-budynok/"
            />
            <TariffGlobal
              tariffGName="Інтернет в квартиру"
              tariffGPrice="120"
              tariffGSpeed="100"
              tariffGImageFluid={flat.fluid}
              tariffGURL="/internet-v-kvartyru/"
            />
            <TariffGlobal
              tariffGName="Інтернет для бізнесу"
              tariffGPrice="500"
              tariffGSpeed="1000"
              tariffGImageFluid={store.fluid}
              tariffGURL="/internet-v-ofis/"
            />
          </Row>

          <Reasons />
        </Container>
      </section>
      <Container>
        <div className="my-5">
          <div className="h2">Інтернет-Телебачення</div>
          <CardDeck>
            {tvproviders.nodes.map(provider => (
              <Card key={provider.id} className="tvprovider-thumb-card">
                <Link
                  className="tvprovider-thumb-card-link"
                  to={`/telebachennya/${provider.seo.slug}/`}
                >
                  <Img
                    className="tvprovider-thumb-card-img"
                    fixed={provider.logo.fixed}
                    alt={`${provider.name} лого`}
                  />
                </Link>
              </Card>
            ))}
          </CardDeck>
        </div>

        <div
          className="my-5"
          dangerouslySetInnerHTML={{
            __html: city.description.childMarkdownRemark.html,
          }}
        ></div>
      </Container>
      <OfferCarousel offers={offers.nodes} />
      <MapCover />
      <LatestPosts />
    </Layout>
  )
}

export default CityTemplate
