import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
// import Card from "react-bootstrap/Card"

export default () => (
  <StaticQuery
    query={graphql`
      {
        contentfulAsset(id: { eq: "d7bdce6c-6ff6-570b-9193-cf412dc10cdf" }) {
          fixed(width: 400) {
            ...GatsbyContentfulFixed_withWebp
          }
          fluid(maxWidth: 1024, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        allContentfulCity(
          sort: { fields: cityName, order: ASC }
          filter: { node_locale: { eq: "uk" }, isPublished: { eq: true } }
        ) {
          nodes {
            cityName
            id
            district {
              name
            }
            seo {
              slug
            }
            cityImage {
              fixed(width: 32, height: 32) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
      }
    `}
    render={data => (
      <section className="cover my-5 py-5">
        {/* <Container>
          <h2 className="mb-4">Карта покриття</h2>
          <Row>
            <iframe
              src="https://www.google.com/maps/d/u/0/embed?mid=1UPD3tg-YtP82puc4PyRctXt4mes&ll=50.409206482921185%2C30.126696449968904&z=12"
              width="1140"
              height="480"
              title="Newtelecom - карта покриття"
            ></iframe>
          </Row>
        </Container> */}
        <Container>
          <h2 className="mb-4 gradient-text text-uppercase">Покриття мережі</h2>
          <p>
            Перелік населених пунктів, де ми працюємо і можна підключити
            інтернет і телебачення від Нового Телекому вже зараз.
          </p>
          <Img
            fluid={data.contentfulAsset.fluid}
            alt="карта покриття"
            className="cover-map-img my-3"
          />
          <Row className="covered-cities-list">
            {data.allContentfulCity.nodes.map(city => (
              <Col
                key={city.id}
                className="covered-city my-1 text-muted"
                lg={4}
                md={6}
                sm={12}
              >
                <Link
                  to={`/internet-${city.seo.slug}/`}
                  className="covered-cities-link"
                >
                  {city.cityName}
                </Link>
                , {city.district.name} район
                {/* {index === arr.length - 1 ? "." : ", "} */}
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    )}
  />
)
